.modal-devis {
  &__title {
    font-size: 15px;
    font-weight: $font-weight-semibold;
    line-height: 1.2; // 18px

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-right: 64px;
    }
  }

  .modal-header,
  .modal-footer {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  .modal-header {
    padding-top: $grid-gutter-width;
  }

  .modal-body {
    padding: $grid-gutter-width;
  }

  .modal-footer {
    padding-bottom: $grid-gutter-width;
  }
}
