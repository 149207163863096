.fotorama {
  $fotorama: &;

  &__stage {
    .gallery--360show & {
      display: none;
    }
  }

  &__thumb {
    border-radius: 5px;

    &::before {
      @include loader;
      left: 50%;
      position: absolute;
      top: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }

    .gallery--360show & {
      cursor: not-allowed;
      filter: grayscale(1);
      pointer-events: none;
    }
  }

  &__nav-wrap {
    .gallery--360 & {
      width: calc(100% - 121px);
    }
  }

  &__nav {
    &::after,
    &::before {
      display: none;
    }
  }
}
