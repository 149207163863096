.turnkey {
  $parent: &;

  background: $white;

  @include media-breakpoint-up(lg) {
    box-shadow: 0px 6px 16px rgba($black, 0.12);
    border-radius: 10px;
    margin-top: 30px;
    max-width: 420px;
    width: 100%;
  }

  &__title {
    background: $gold-2;
    color: $white;
    font-size: 21px;
    line-height: 1.19; // 25px
    margin-bottom: 0;
    padding: 20px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
      border-radius: 10px 10px 0 0;
      font-size: 24px;
      line-height: 1.208; // 29px
      padding-bottom: 18px;
      padding-top: 18px;
    }
  }

  &__content {
    padding: 24px 20px 17px;

    @include media-breakpoint-up(md) {
      padding-bottom: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__row {
    font-size: 14px;
    line-height: 1.214; // 17px

    &:not(:last-child) {
      border-bottom: 1px solid rgba($color-main, 0.25);
      padding-bottom: 20px;
    }

    &:not(:nth-last-child(-n + 2)) {
      margin-bottom: 24px;
    }

    &:last-child {
      padding-top: 11px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: 1.188; // 19px
    margin-bottom: 8px;
  }

  &__datas {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    td {
      padding: 4px 0;
    }

    &-value {
      text-align: right;
    }
  }

  &__logo {
    display: inline-block;
    margin-right: 9px;
    max-width: 34px;
  }

  &__price {
    font-size: 21px;
    line-height: 1.19; // 25px
  }

  &__cta {
    text-align: center;

    &:first-of-type {
      margin-bottom: 30px;
    }

    &:nth-of-type(2) {
      margin-bottom: 10px;
    }
  }

  &__contact {
    text-decoration: underline;

    @include plain-hover-focus() {
      color: $color-main;
    }

    @include hover-focus-active() {
      text-decoration: none;
    }
  }

  &--sticky {
    position: sticky;
    top: 32px;
  }

  // Si présence d'un formulaire
  &.aside--form {
    #{$parent} {
      &__title {
        border-bottom: 1px solid $color-main;
      }

      &__content {
        padding: 0;
      }

      &__row {
        padding-top: 0;
      }
    }
  }
}
