.reviews {
  &__container {
    margin-bottom: 39px;
  }

  &__list {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
    padding-top: 35px;
    position: relative;
    white-space: nowrap;

    @include media-breakpoint-up(xl) {
      overflow: initial;
      white-space: normal;
    }

    .user-review {
      &:not(:last-child) {
        @include media-breakpoint-down(xl) {
          margin-right: 26px;
        }
      }
    }
  }

  &__more {
    font-size: 16px;
    line-height: 1.188;
    margin-bottom: 74px;
    text-align: center;

    @include media-breakpoint-up(xl) {
      margin-bottom: 80px;
    }

    &-link {
      background: url(inline-icons("arrow-right", $color-main)) no-repeat right
        center;
      background-size: 16px 11px;
      padding-right: 24px;

      @include plain-hover-focus() {
        color: $color-main;
      }
    }
  }
}
