.sticky-links {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 900;

  @include media-breakpoint-up(xl) {
    left: auto;
    transform: translateY(-50%);
    top: 50%;
  }

  &__item {
    transition: transform 0.7s;

    @include media-breakpoint-down(lg) {
      width: 50%;
    }

    &:only-child {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    &:not(:last-child) {
      @include media-breakpoint-down(lg) {
        border-right: 1px solid $white;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }
  }

  &__link {
    background: $green-1;
    font-weight: $font-weight-bold;
    min-height: 40px;
    padding: 0 8px;
    text-align: center;
    text-transform: uppercase;

    @include plain-hover-focus() {
      color: $white;
      text-decoration: none;
    }

    &:hover {
      background: darken($green-1, 5%);
    }
  }

  &__icon {
    margin-right: 8px;
    max-width: 18px;
  }

  &__text {
    font-size: 12px;
    line-height: 1.214;
  }

  .icon {
    fill: $white;
    width: 100%;
  }
}
