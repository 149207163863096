.modal-booking {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  .modal-header {
    padding-top: $grid-gutter-width;
  }

  .modal-footer {
    padding-bottom: $grid-gutter-width;
    padding-top: 16px;
  }
}
