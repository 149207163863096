.team {
  text-align: center;

  &__figure,
  &__img {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
  }

  &__content {
    margin: 16px auto 24px;
  }

  h3#{&}__title {
    font-size: 20px;
    line-height: 1.2; // 24px;
    margin: 0 auto;
    text-align: center;
  }
}
