.form-newsletter {
  margin-bottom: 25px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
    max-width: 290px;
  }

  &__group {
    margin-bottom: 0;
    position: relative;
  }

  &__control {
    border: 1px solid $white;
    height: 50px;
    line-height: 1;

    @include plain-hover-focus() {
      background: none;
      color: $white;
    }

    &::placeholder {
      color: $white;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $white;
    }

    &::-ms-input-placeholder {
      color: $white;
    }
  }

  &__submit {
    background: none;
    border: none;
    position: absolute;
    right: 16px;
    top: 10px;
    width: 16px;
    padding: 0;
    margin: 0;
  }
}
