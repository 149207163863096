.card {
  border-radius: 10px;
  color: $white;
  min-height: 258px;
  overflow: hidden;
  position: relative;
  width: 212px;

  &__link,
  &__visual,
  &__img {
    display: block;
    margin: 0;
  }

  &__link {
    height: 100%;

    @include plain-hover-focus() {
      color: $white;
      text-decoration: none;
    }
  }

  &__visual {
    height: 100%;
    position: relative;

    &::after {
      background: linear-gradient(180deg, rgba($black, 0) 21.71%, $black 100%);
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__img {
    transition: 4s;

    &:hover {
      transform: scale(1.2);
    }

    // &:not([src]) {
    //   background: url("../images/logo-benoe.png") no-repeat center;
    //   background-size: contain;
    //   color: transparent;
    //   position: absolute;
    //   left: 50%;
    //   top: 25%;
    //   height: 100px;
    //   width: 100px;
    //   transform: translate(-50%, -50%);
    //   filter: grayscale(1);
    // }
  }

  &__body {
    bottom: 0;
    left: 0;
    min-height: 127px;
    padding: 0 $grid-gutter-width/2 11px;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__title {
    font-size: 18px;
    line-height: 1.222;
    margin: 0 0 7px;
  }

  &__desc {
    font-size: 12px;
    line-height: 1.333;
  }
}
