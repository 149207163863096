// Menu trigger
.menu-trigger {
  background: url("../images/bg-menu-trigger.svg") no-repeat left top;
  border: none;
  height: 16px;
  padding: 0;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
