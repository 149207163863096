.masthead {
  // padding: 0 0 41px;
  margin-bottom: 82px;

  &__header {
    background: url("../images/masthead-bg-mobile.png") no-repeat center top;
    background-size: cover;
    margin-bottom: -27px;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-bottom: -8%;
    }

    @include media-breakpoint-up(lg) {
      background-image: url("../images/masthead-bg-desktop.png");
      background-size: auto;
      margin-bottom: -128px;
      min-height: 430px;
    }

    &::before {
      @include media-breakpoint-down(md) {
        content: "";
        display: block;
        padding-top: percentage(247/828);
      }
    }
  }

  &__title {
    color: $white;
    font-weight: $font-weight-normal;
    font-family: $open-sans;
    font-size: 24px;
    left: 50%;
    line-height: 1.333; // 32px
    margin: 0 auto;
    max-width: 276px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -70%);
    width: 100%;

    @include media-breakpoint-up(md) {
      font-size: 31px;
      max-width: none;
    }

    @include media-breakpoint-up(lg) {
      font-size: 42px;
      top: 160px;
      transform: translateX(-50%);
    }

    @include media-breakpoint-up(xl) {
      font-size: 54px;
      line-height: 1.296;
    }
  }
}
