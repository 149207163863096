// Color system
$color-main: #393106;
$blue-1: #000f5c;
$blue-2: #3b5998;
$gold-1: #e6c234;
$gold-2: #c2a763;
$gold-3: #e7c854;
$gold-4: #b79338;
$gold-5: #c2a763;
$gray-1: #f5f5f5;
$gray-2: #f7f7fb;
$gray-3: #f0f0f0;
$gray-4: #f6f6f6;
$gray-5: #c4c4c4;
$gray-6: #eeeeee;
$gray-7: #b3b3b3;
$green-1: #2baf90;
$green-2: #44b000;
$red-1: #d60000;
$yellow-1: #ffdf4d;
$black: #000000;
$white: #ffffff;

$main: $green-1;
$options: $green-2;
$cta: $yellow-1;
$contact: $yellow-1;
$cancel: $color-main;

$theme-colors: (
  "main": $main,
  "options": $options,
  "cta": $cta,
  "contact": $contact,
  "cancel": $cancel,
);

// Font
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Bootstrap - custom variables
$font-family-base: "Inter", sans-serif;
$open-sans: "Open Sans", sans-serif;
$body-color: $color-main;

// Typography
$font-size-base: 1rem;
$font-size-xs: $font-size-base * 0.75;

// $headings-margin-bottom
// $paragraph-margin-bottom
// $font-weight-bolder

// Input
// $input-border-radius: 5px;
// $input-bg: transparent;
// $input-color: $white;
// $input-border-color: $white;

// $input-placeholder-color: $color-main;

// $input-padding-y: 1.0625rem; // 17px
// $input-padding-x: 1rem;

// $input-font-size: 14px;
// $input-line-height: 1.214;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1190px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns: 12 !default;
// $grid-gutter-width: 30px !default;
// $grid-row-columns: 6 !default;

/**
 * Bootstrap Override
 */

// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 1.438rem; // 23px
$btn-font-size: 0.875rem;
$btn-line-height: 1.214;

$btn-padding-y-sm: 0.625rem; // 10px
$btn-font-size-sm: $font-size-xs;
$btn-line-height-sm: 1.333;

$btn-border-radius: 0.3125rem;
$btn-font-weight: 700;

// Forms

$input-padding-y: 0.5625rem; // 9px
$input-padding-x: 0.75rem; // 12px
$input-font-size: 0.938rem; // 15px
$input-line-height: 1.2; // 18px => 1.200

$input-disabled-bg: $gray-3;
$input-color: $color-main;
$input-border-color: $color-main;
$input-border-radius: 0.3125rem; // 5px

// form-control sm
/*
$input-padding-y-sm: .5625rem;  // 9px
$input-padding-x-sm: 0.75rem;   // 12px

$input-font-size-sm:    0.938rem; // 15px
$input-line-height-sm:  1.200;    // 18px => 1.200

$input-border-radius-sm: .3125rem; // 5px
*/

// Custom select
$custom-select-padding-x: $input-padding-x;
$custom-select-bg-size: 10px 5px;
$custom-select-background: url(inline-icons("arrow-bottom", $color-main))
  no-repeat right $custom-select-padding-x center / $custom-select-bg-size;

// Custom range
// $custom-range-track-height: 0.0625rem;
// $custom-range-track-bg: $color-main;

// $custom-range-thumb-width: 1.0625rem; // =17px (valeur du rond du range)
// $custom-range-thumb-height: 1.0625rem; // =17px (valeur du rond du range)
// $custom-range-thumb-bg: $yellow-1;
// $custom-range-thumb-focus-box-shadow: none;

// Input group
$input-group-addon-bg: $white;

// Dropdown
$dropdown-padding-y: 1rem; // 16px

// Modal
$modal-xl: 1240px;
$modal-lg: 712px;

$modal-header-border-color: $white;
