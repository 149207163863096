.form-contact {
  @include media-breakpoint-up(lg) {
    padding-right: $grid-gutter-width;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    [class*="col"] {
      &:not(:last-child) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 24px;
        }
      }
    }

    &--submit {
      text-align: right;
    }
  }

  &__label {
    font-size: 18px;
    line-height: 1.222; // 22px
    margin-bottom: 8px;

    &.required {
      position: relative;
    }
  }

  &__submit {
    min-width: 160px;
  }
}
