.capacities {
  &__item {
    font-size: 16px;
    line-height: 1.188; // 19px
    text-align: center;

    &:nth-child(-n + 3) {
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }

    &:nth-child(4) {
      @include media-breakpoint-down(sm) {
        padding-left: 38px;
      }
    }

    &:nth-child(5) {
      @include media-breakpoint-down(sm) {
        padding-right: 38px;
      }
    }
  }

  .icon {
    display: block;
    margin: 0 auto 10px;
  }

  &__subtitle {
    display: block;
    font-weight: $font-weight-semibold;
    margin-bottom: 7px;
  }
}
