.event-recap {
  &__item {
    width: percentage(155/320);

    &:nth-child(-n + 2) {
      margin-bottom: 10px;
    }

    &--date {
      @include field-icon("calendar", 15px, 15px, 12px, $color-main);
      position: relative;

      input {
        padding-left: 34px;
      }
    }
  }

  .form-control {
    overflow: hidden;
    white-space: nowrap;
  }
}
