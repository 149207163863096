.ranking {
  &:not(:last-child) {
    margin-bottom: 61px;
  }

  &__title {
    line-height: 1.214;
    margin-bottom: 32px;
    text-align: center;

    &-icon {
      display: inline-block;
      margin-left: 9px;
      vertical-align: bottom;
    }
  }

  &__list {
    overflow-x: scroll;
    padding-bottom: 10px;
    white-space: nowrap;

    @include media-breakpoint-up(xl) {
      overflow-x: initial;
      white-space: normal;
    }
  }

  .card {
    white-space: normal;

    &:not(:last-child) {
      margin-right: 25px;

      @include media-breakpoint-up(xl) {
        margin-right: 0;
      }
    }
  }

  &:first-child {
    .ranking__title-icon {
      width: 23px;
    }
  }

  &:nth-child(2) {
    .ranking__title-icon {
      width: 28px;
    }
  }

  &:last-child {
    .ranking__title-icon {
      margin-left: 8px;
      width: 30px;
    }
  }
}
