.shadow-selector {
  position: relative;

  &__btn {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    white-space: initial;

    .form-filters & {
      left: 5px;
      width: calc(100% - 10px);
    }

    &.selected {
      opacity: 0;
    }
  }
}
