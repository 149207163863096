.form-sorting {
  &__legend,
  &__label {
    font-size: 15px;
    line-height: 1.2; // 18px
    margin: 0;
  }

  &__legend {
    left: 9px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }

  // Affichage
  &__view {
    @include media-breakpoint-up(lg) {
      padding-left: 98px;
    }

    &-group {
      @include media-breakpoint-up(lg) {
        min-width: 276px;
      }
    }
  }

  // Override Bootstrap elements in this context
  .custom-radio {
    &:first-child {
      .custom-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      .custom-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .form-check-input {
    &:checked + .custom-label {
      background: $color-main;
      color: $white;

      &--map {
        @include field-icon("map", 16px, 16px, 24px, $white, true);
      }
      &--list {
        @include field-icon("list", 16px, 12px, 24px, $white, true);
      }
    }
  }

  .custom-label {
    background: $gray-4;
    border: none;
    cursor: pointer;
    padding-left: 54px;
    position: relative;

    @include media-breakpoint-up(md) {
      padding-left: 40px;
    }

    &--map {
      @include field-icon("map", 16px, 16px, 24px, $color-main);
    }
    &--list {
      @include field-icon("list", 16px, 12px, 24px, $color-main);
    }

    &::before {
      @include media-breakpoint-up(md) {
        left: 12px;
      }
    }
  }
}
