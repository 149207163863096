.contact {
  background: $white;
  padding: 0 18px;
  position: relative;

  @include media-breakpoint-up(md) {
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  &__main {
    padding-top: 32px;

    @include media-breakpoint-up(xl) {
      order: 1;
      padding-bottom: 26px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__title {
    font-size: 26px;
    line-height: 1.192; // 31px
    margin-bottom: 24px;
  }

  &__text {
    font-size: 18px;
    line-height: 1.222; // 22px
    margin-bottom: 48px;

    p {
      &:not(:last-child) {
        margin-bottom: 16px;

        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__footer {
    padding: 48px 0 38px;

    @include media-breakpoint-up(xl) {
      border-right: 1px solid $color-main;
      padding: 32px 33px;
    }

    p {
      margin-bottom: 27px;
    }
  }

  &__footer,
  &__subtitle {
    font-size: 18px;
    line-height: 1.222; // 22px
  }

  &__subtitle {
    margin-bottom: 18px;
  }

  &__mail {
    @include plain-hover-focus-active() {
      color: $color-main;
    }
  }

  &__brand {
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 1.2; // 24px
    padding-top: 43px;
    text-align: center;
  }

  &__logo {
    display: block;
    margin: 0 auto 15px;
    max-width: 134px;
  }

  &__baseline {
    display: inline-block;
    max-width: 276px;
  }
}

.page-contact {
  background: $gray-2;

  &__container {
    padding-top: 40px;
  }
}
