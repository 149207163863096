.loading {
  bottom: 0;
  height: 2em;
  margin: auto;
  overflow: show;
  right: 0;
  width: 2em;
  z-index: 999;

  &,
  &::before {
    left: 0;
    position: fixed;
    top: 0;
  }

  &::before,
  &:not(:required):after {
    content: "";
    display: block;
  }

  &::before {
    background-color: rgba($black, 0.4);
    width: 100%;
    height: 100%;
  }

  &:not(:required) {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
  }

  &:not(:required):after {
    animation: spinner 1.5s infinite linear;
    box-shadow: rgba($white, 0.8) 1.5em 0 0 0, rgba($white, 0.8) 1.1em 1.1em 0 0,
      rgba($white, 0.8) 0 1.5em 0 0, rgba($white, 0.8) -1.1em 1.1em 0 0,
      rgba($white, 0.8) -1.5em 0 0 0, rgba($white, 0.8) -1.1em -1.1em 0 0,
      rgba($white, 0.8) 0 -1.5em 0 0, rgba($white, 0.8) 1.1em -1.1em 0 0;
    border-radius: 0.5em;
    font-size: 10px;
    height: 1em;
    margin-top: -0.5em;
    position: relative;
    width: 1em;
  }
}
