.form-group[class*="filter"] {
  width: 100%;
}

$section-establishment: ".section-filters--establishment";
$section-parcours: ".section-filters--parcours";

.filter {
  &-regions {
    @include media-breakpoint-up(xl) {
      max-width: 146px;
    }

    .custom-select {
      @include field-icon("place", 14px, 17px, 17px, $color-main);
    }

    #{$section-establishment} & {
      @include media-breakpoint-up(xl) {
        max-width: 166px;
      }
    }
  }

  &-date {
    @include field-icon("calendar", 15px, 15px, 17px, $color-main);

    @include media-breakpoint-up(xl) {
      max-width: 162px;
    }

    #{$section-establishment} & {
      @include media-breakpoint-up(xl) {
        max-width: 162px;
      }
    }

    #{$section-parcours} & {
      @include media-breakpoint-up(xl) {
        max-width: 162px;
      }
    }
  }

  &-price {
    @include field-icon("euro-sign", 13px, 14px, 17px, $color-main);

    @include media-breakpoint-up(xl) {
      max-width: 178px;
    }

    #{$section-establishment} & {
      @include media-breakpoint-up(xl) {
        max-width: 168px;
      }
    }
  }

  &-participants {
    @include field-icon("participant", 12px, 15px, 17px, $color-main);

    @include media-breakpoint-up(xl) {
      max-width: 180px;
    }
  }

  &-gamme {
    @include field-icon("gamme", 18px, 12px, 12px, $color-main);

    @include media-breakpoint-up(xl) {
      max-width: 156px;
    }

    #{$section-establishment} & {
      @include media-breakpoint-up(xl) {
        max-width: 146px;
      }
    }

    #{$section-parcours} & {
      @include media-breakpoint-up(xl) {
        max-width: 305px;
      }
    }

    .filter-gamme-text {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  &-privatization {
    @include field-icon("private", 12px, 14px, 17px, $color-main);

    @include media-breakpoint-up(xl) {
      max-width: 168px;
    }
  }

  &-criteria {
    @include media-breakpoint-up(xl) {
      max-width: 180px;
    }

    & > .dropdown > .custom-select {
      @include field-icon("etc", 18px, 4px, 10px, $color-main);
    }
  }

  &-venues {
    @include media-breakpoint-up(xl) {
      max-width: 230px;
    }
  }

  &-features {
    @include media-breakpoint-up(xl) {
      max-width: 170px;
    }
  }

  &-technical {
    @include media-breakpoint-up(xl) {
      max-width: 170px;
    }
  }

  &-parking {
    text-align: center;

    @include media-breakpoint-up(xl) {
      max-width: 130px;
    }
  }

  &-bestof {
    @include media-breakpoint-up(xl) {
      max-width: 205px;
    }

    #{$section-establishment} & {
      @include media-breakpoint-up(xl) {
        max-width: 142px;
      }
    }
  }

  &-search {
    @include field-icon("search", 16px, 16px, 17px, $color-main);

    @include media-breakpoint-up(xl) {
      .section-filters:not(.section-filters--establishment) & {
        max-width: 230px;
      }
    }

    .form-control {
      padding-left: 36px;
    }
  }

  &-parking {
    .form-check-input {
      &:checked + .form-check-label {
        background: $yellow-1;
        border-color: $yellow-1;
      }
    }

    .form-check-label {
      cursor: pointer;
    }
  }

  &-icon {
    & > .form-control,
    & > .custom-select,
    & > .dropdown > .custom-select {
      border-color: $white;
      padding-left: 36px;
    }
  }

  &-dropdown {
    border-color: $color-main;
    border-radius: 5px;
    padding: 0;
    width: calc(100% - 10px);
    left: 5px;
  }

  &-list {
    max-height: 282px;
    overflow-y: auto;

    @include media-breakpoint-up(xl) {
      max-height: 400px;
    }

    &__item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-main;
      }
    }

    &__input {
      &:checked + .filter-list__label {
        &::after {
          opacity: 1;
        }
      }
    }

    &__text,
    &__label {
      color: $color-main;
      font-size: 15px;
      line-height: 1.2; // 18px
      padding: 12px 12px 12px 42px;
      position: relative;
      margin: 0 auto;
      width: 100%;
    }

    &__text {
      display: inline-block;
      font-weight: $font-weight-semibold;
      padding-left: 12px;
    }

    &__label {
      cursor: pointer;

      &::before {
        background: $white;
        border: 1px solid $color-main;
        border-radius: 2px;
        content: "";
        display: block;
        height: 18px;
        left: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
      }

      &::after {
        background: $gold-4;
        border-radius: 1px;
        content: "";
        display: block;
        height: 12px;
        left: 15px;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
      }

      &--radio {
        &::after,
        &::before {
          border-radius: 50%;
        }
      }
    }

    &__button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
