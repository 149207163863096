.clients {
  margin-bottom: 77px;
  min-height: 80px;
  position: relative;

  @include media-breakpoint-up(xl) {
    margin-bottom: 116px;
  }

  &__container {
    &:empty {
      min-height: 80px;
      position: relative;

      &::before {
        @include loader($size: 32px);
        left: 50%;
        position: absolute;
        top: 50%;
        margin-top: -16px;
        margin-right: -16px;
      }
    }
  }

  &-list {
    &__logo {
      display: block;
    }

    .owl-stage {
      display: flex;
      align-items: center;
    }
  }
}
