.modal-animations {
  $modal: &;

  @include media-breakpoint-down(lg) {
    margin: 0 auto;
  }

  &__section {
    padding: 0 35px;

    @include media-breakpoint-up(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }

    &.modal-header {
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }

    &.modal-body {
      @include media-breakpoint-down(lg) {
        padding-bottom: 120px !important;
        padding-top: 10px !important;
      }

      & > .modal-animations__title {
        @include media-breakpoint-down(lg) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .modal-content {
    @include media-breakpoint-down(lg) {
      border-radius: 0;
    }
  }

  .modal-body {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__title {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 1.214; // 17px
    // margin-bottom: 24px;

    @include media-breakpoint-up(xl) {
      font-size: 18px;
      line-height: 1.222; // 22px
    }
  }

  [data-step] {
    &::before {
      align-items: center;
      background: $color-main;
      border-radius: 50%;
      content: attr(data-step);
      color: $white;
      display: inline-flex;
      font-size: 16px;
      flex-shrink: 0;
      font-weight: 900;
      height: 24px;
      line-height: 1.188; // 19px
      margin-right: 16px;
      text-align: center;
      justify-content: center;
      width: 24px;

      @include media-breakpoint-up(xl) {
        font-size: 18px;
        line-height: 1.222; // 22px
        height: 30px;
        width: 30px;
      }
    }
  }

  &__submit {
    &[data-step] {
      &::before {
        background: $green-1;
      }
    }

    &.is-disabled {
      &[data-step] {
        &::before {
          @include disabled-item;
        }
      }
    }

    &-btn {
      min-width: 260px;

      &[disabled] {
        @include plain-hover-focus {
          @include disabled-item;
        }
      }
    }
  }

  &__validation {
    background: rgba($white, 0.98);
    display: flex;
    height: 100%;
    left: 0;
    padding-top: 30px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;

    @include media-breakpoint-up(xl) {
      border-radius: 10px;
      bottom: 30px;
      left: 40px;
      right: 40px;
      top: 30px;
      padding-top: 0;
      width: auto;
      height: auto;
    }

    .btn {
      min-width: 258px;

      &:not(:last-child) {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
          margin-right: 99px;
        }
      }
    }

    #{$modal} {
      &__title {
        margin-bottom: 56px;
      }
    }
  }

  .modal-footer {
    background: $white;
    padding-bottom: 30px;

    @include media-breakpoint-down(lg) {
      border: 1px solid $color-main;
      border-bottom: none;
      bottom: 0;
      left: 0;
      padding: 0;
      position: fixed;
      right: 0;
      z-index: 5;
    }

    &__choice,
    &__validate {
      @include media-breakpoint-down(lg) {
        padding: 10px 12px;
      }
    }

    &__choice {
      padding-top: 8px;
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $color-main;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
    &__validate {
      @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }
}
