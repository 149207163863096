.footer-site {
  background: $color-main;
  color: $white;

  &__main {
    border-bottom: 1px solid rgba($white, 0.5);
    padding: 51px 0 0;

    @include media-breakpoint-up(lg) {
      position: relative;
    }
  }

  &__branding {
    margin-bottom: 51px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto 2px;
    max-width: 110px;
  }

  &__baseline {
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 1.214;
  }

  &__aside {
    padding: 61px 0 56px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 76px;
      padding-top: 48px;
    }
  }
}
