.contact-infos {
  @include media-breakpoint-up(xl) {
    margin-left: 62px;
  }

  &__link {
    @include media-breakpoint-up(xl) {
      order: 1;
    }
  }

  &__txt {
    font-size: 14px;
    line-height: 1.143;
    margin: 28px 0 0;
    text-align: center;

    @include media-breakpoint-up(xl) {
      font-size: 18px;
      margin-top: 0;
      margin-right: 32px;
    }

    &-secondary {
      display: block;

      span {
        font-weight: $font-weight-bold;
        color: $gold-3;
      }

      strong {
        @include media-breakpoint-up(xl) {
          background: url(inline-icons("phone", $color-main)) no-repeat left
            center;
          background-size: 15px 19px;
          display: inline-block;
          font-weight: $font-weight-normal;
          line-height: 1.167; // =21px
          padding-left: 27px;
        }
      }
    }
  }
}
