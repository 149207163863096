:root {
  // --litepickerBgColor: #fff;

  --litepickerMonthHeaderTextColor: #393106 !important;

  // --litepickerMonthButton: #9e9e9e;
  // --litepickerMonthButtonHover: #2196f3;
  // --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 7); // 7 days
  // --litepickerMonthWeekdayColor: #9e9e9e;

  --litepickerDayColor: #393106 !important;
  --litepickerDayColorHover: #393106 !important;
  --litepickerDayIsTodayColor: #c2a763 !important;
  --litepickerDayIsInRange: #f5f5f5 !important;
  // --litepickerDayIsLockedColor: #9e9e9e;
  // --litepickerDayIsBookedColor: #9e9e9e;
  --litepickerDayIsStartColor: #393106 !important;
  --litepickerDayIsStartBg: #ffdf4d !important;
  --litepickerDayIsEndColor: #393106 !important;
  --litepickerDayIsEndBg: #ffdf4d !important;
  // --litepickerDayWidth: 38px;

  // --litepickerButtonCancelColor: #fff;
  // --litepickerButtonCancelBg: #9e9e9e;
  // --litepickerButtonApplyColor: #fff;
  // --litepickerButtonApplyBg: #2196f3;

  // --litepickerButtonResetBtn: #909090;
  // --litepickerButtonResetBtnHover: #2196f3;

  // --litepickerHighlightedDayColor: #333;
  // --litepickerHighlightedDayBg: #ffeb3b;
}
// .show-week-numbers {
//   --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 8);
// }
