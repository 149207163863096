.panel {
  background: $white;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__header,
  &__title {
    font-size: 16px !important;
    line-height: 1.188 !important; // 19px
  }

  &__header {
    background: $white;
    border-radius: 5px;
    min-height: 60px;
    position: relative;
    padding: 9px 24px 10px;

    &-wrap {
      width: calc(100% - 30px);
    }
  }

  &__icon {
    margin-right: 24px;
    max-width: 30px;

    @include media-breakpoint-up(md) {
      margin-right: 30px;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    margin-top: 3px;
  }

  .icon {
    width: 100%;
  }

  &__btn {
    background: none;
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
      background: url(inline-icons("arrow-bottom")) no-repeat center top;
      background-size: cover;
      content: "";
      height: 6px;
      position: absolute;
      right: 12px;
      top: 27px;
      width: 10px;
    }

    &:not(.collapsed) {
      &::after {
        top: 26px;
        transform: rotate(180deg);
      }
    }

    @include hover-focus-active() {
      outline: none;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 1.214; // 17px
    padding: 6px 24px 24px;
  }

  &__body {
    margin-bottom: 32px;
    overflow: hidden;

    p {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    a {
      color: $gold-4;
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }
  }

  &__wrap {
    max-height: 126px;

    .panel__content--deploy & {
      max-height: initial;
    }
  }

  &__img {
    border-radius: 5px;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      float: right;
      margin: 0 0 35px 55px;
      max-width: 173px;
    }
  }

  h2,
  h3 {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 1.214; // 17px
    margin-bottom: 0;
  }

  &__seemore {
    background: none;
    border: 0;
    padding: 0;
    text-decoration: underline;

    .selected {
      display: none;
    }

    .panel__content--deploy & {
      .default {
        display: none;
      }

      .selected {
        display: block;
      }
    }

    @include hover-focus-active() {
      text-decoration: none;
    }
  }
}
