.turnkey-services {
  list-style-type: disc;
  padding-left: 1rem;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
