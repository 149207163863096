.field-radio {
  $field: &;

  &:first-child {
    @include media-breakpoint-up(md) {
      margin-right: 10px;
    }
  }

  &__input {
    &:checked + #{$field}__label {
      @include media-breakpoint-down(sm) {
        background: $white;
        color: $gold-1;
      }

      &::before {
        @include media-breakpoint-up(md) {
          background: $gold-1;
        }
      }
    }
  }

  &__label {
    cursor: pointer;
    width: 100%;

    @include media-breakpoint-down(sm) {
      border: 1px solid $white;
      border-radius: 5px;
      font-size: 14px;
      font-weight: $font-weight-bold;
      line-height: 1.214;
      padding: 10px 18px 11px;
      min-width: 130px;
      text-align: center;
    }
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 1.188;
      padding-left: 34px;
      position: relative;
    }

    &::before {
      @include media-breakpoint-up(md) {
        background: $white;
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 4px solid $white;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--secondary {
    color: $white;

    #{$field} {
      &__input {
        &:checked + #{$field}__label {
          &::before {
            @include media-breakpoint-up(md) {
              background: $gold-2;
            }
          }
        }
      }

      &__label {
        &::before {
          @include media-breakpoint-up(md) {
            background: $white;
            border-color: $white;
          }
        }
      }
    }
  }
}
