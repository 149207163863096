// Layout
.page {
  &__results,
  &__article {
    background: $gray-2;
  }

  &__article {
    padding-top: 1px;
  }

  &__results {
    @include media-breakpoint-up(xl) {
      min-height: calc(100vh - 100px);
    }

    .results__container {
      padding-bottom: 16px;
    }
  }

  &__product {
    position: relative;
    padding-top: 80px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 46px;
    }

    .site-content__wrapper {
      @include media-breakpoint-up(lg) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }

      @include media-breakpoint-up(xl) {
        background: $white;
        border: 1px solid $color-main;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 24px 38px;
        margin: 0 auto;
        max-width: 1237px;
      }
    }
  }
}
