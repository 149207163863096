.section-sort {
  padding-top: 22px;
  padding-bottom: 14px;

  @include media-breakpoint-up(xl) {
    min-height: 68px;
    padding-bottom: 0;
    padding-top: 0;
  }

  &__title {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2; // 18px

    &:not(:only-child) {
      margin-bottom: 18px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }

    &.is-loading {
      display: flex;
      align-items: center;

      &::before {
        @include loader;
        margin-right: 16px;
      }
    }
  }
}
