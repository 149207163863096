.header-site {
  background: $white;
  min-height: 67px;

  &__branding {
    padding: 13px 0 14px;
    position: relative; // NOTE: lié au fait que le bouton trigger est à l'intérieur de l'élément. Voir si on peut faire différemment.

    @include media-breakpoint-up(xl) {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  &__content {
    @include media-breakpoint-down(lg) {
      background: $white;
      height: calc(100vh - 67px);
      padding: 24px $grid-gutter-width;
      position: fixed;
      right: 0;
      top: 67px;
      transition: all 1s;
      transform: translateX(100%);
      width: 100%;
      z-index: 1000;
    }

    .menu-open & {
      @include media-breakpoint-down(lg) {
        transform: translateX(0);
      }
    }
  }

  &__nav {
    margin-bottom: 18px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }
}
