.animation-selector {
  $parent: &;

  @include media-breakpoint-up(xl) {
    border-bottom: 1px solid $color-main;
    border-left: 1px solid $color-main;
    max-height: 128px;
    position: relative;
    width: 165px;
  }

  &__content {
    font-size: 16px;
    height: 56px;
    line-height: 1.188; // =19px
    padding: 0;
    position: relative;
    text-align: center;

    @include media-breakpoint-down(lg) {
      border: 1px solid $gray-7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-up(xl) {
      border-bottom: 1px solid $color-main;
      height: 96px;
      padding: 8px 15px 28px;
    }

    &:hover {
      background: $color-main;
      color: $white;
    }
  }

  &__title {
    overflow-wrap: anywhere;
    pointer-events: none;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      font-weight: $font-weight-semibold;
    }
  }

  &__more {
    background: none;
    border: none;
    bottom: 0;
    color: currentColor;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      height: 95px;
      bottom: auto;
    }

    &-txt {
      @include media-breakpoint-down(lg) {
        display: none;
      }

      bottom: 12px;
      font-size: 14px;
      font-weight: 500;
      left: 0;
      line-height: 1.214; // =17px
      position: absolute;
      right: 0;
      text-decoration: underline;
    }

    &.collapsed {
      .selected {
        display: none;
      }
    }

    &:not(.collapsed) {
      background: $color-main;
      color: $white;

      & + #{$parent}__title {
        color: $white;
      }

      .default {
        display: none;
      }
    }
  }

  &__add {
    border-radius: 0;
    padding-bottom: 6px;
    padding-top: 7px;
    font-weight: $font-weight-normal;

    .default {
      &::before {
        content: "+ ";
      }
    }

    .selected {
      display: none;
    }

    &[disabled] {
      background: url(inline-icons("checked", $yellow-1)) no-repeat left top;
      background-size: 17px 16px;
      background-position: 14px center;
      border: 1px solid $yellow-1;
      border-radius: 0;
      cursor: not-allowed;
      opacity: 1;
      padding-left: 42px;
      text-align: left;

      .default {
        display: none;
      }

      .selected {
        display: block;
      }

      @include plain-hover-focus() {
        background-color: $gray-1;
        box-shadow: none;
      }
    }
  }

  &:nth-child(1),
  &:nth-child(8) {
    @include media-breakpoint-up(xl) {
      border-left: none;
    }
  }

  &:nth-child(7) {
    @include media-breakpoint-up(xl) {
      border-right: none;
    }
  }

  &:nth-child(n + 8) {
    @include media-breakpoint-up(xl) {
      border-bottom: none;
    }
  }

  &:last-child {
    @include media-breakpoint-up(xl) {
      min-width: 166px;
    }

    &:not(:nth-child(7)):not(:nth-child(14)) {
      @include media-breakpoint-up(xl) {
        border-right: 1px solid $color-main;
      }
    }
  }
}
