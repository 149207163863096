.benefit {
  $parent: &;

  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
    width: percentage(1/3);
  }

  &:first-child {
    @include media-breakpoint-up(xl) {
      padding-right: 80px;
    }
  }

  &:nth-child(2) {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid rgba($white, 0.5);
      border-right: 1px solid rgba($white, 0.5);
    }

    .area-benefits--big & {
      @include media-breakpoint-up(xl) {
        padding-right: 50px;
      }
    }
  }

  &:nth-child(n + 2) {
    @include media-breakpoint-up(xl) {
      padding-left: 69px;
    }

    .area-benefits--big & {
      @include media-breakpoint-up(xl) {
        padding-left: 50px;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__header {
    margin-bottom: 20px;
  }

  &__icon {
    background: $white;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    margin-bottom: 15px;
    position: relative;
    vertical-align: middle;
    width: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-right: 12px;
    }

    .icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0 !important;
  }

  &__center {
    font-size: 16px;
    line-height: 1.625;
  }

  &__desc {
    margin: 0 auto;
    max-width: 295px;

    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }

  &--price {
    .icon {
      width: 13px;
    }
  }

  // In page contact
  .page-contact & {
    &:nth-child(2) {
      @include media-breakpoint-up(lg) {
        border-left: 1px solid rgba($color-main, 0.5);
        border-right: 1px solid rgba($color-main, 0.5);
      }
    }

    #{$parent} {
      &__icon {
        background: $gold-5;

        &,
        path {
          fill: $white;
        }
      }
    }
  }
}
