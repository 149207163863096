.rating-stars {
  $parent: &;

  font-size: 14px;
  line-height: 1.214;
  margin-bottom: 30px;
  text-align: center;
  transition: width 1s;
  opacity: 0;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  &__visual {
    background: url(inline-icons("star", $gray-6)) repeat-x left top;
    background-size: 18px 17px;
    display: inline-block;
    height: 18px;
    position: relative;
    width: calc(18px * 5);

    &::before {
      background: url(inline-icons("star", $yellow-1)) repeat-x left top;
      background-size: 18px 17px;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &__text {
    margin-left: 10px;
  }

  @for $i from 1 through 10 {
    &--score-#{$i * 10} {
      #{$parent}__visual::before {
        width: percentage($i / 10);
      }
    }
  }
}

[class*="rating-stars--score"] {
  opacity: 1;
}
