.search-form {
  $parent: &;

  .masthead & {
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 20px 50px rgba($black, 0.1);
    position: relative;
  }

  &__head {
    background: $gold-1;
    border-radius: 10px 10px 0 0;
    color: $white;
    padding: 40px 23px 14px;
    position: relative;

    @include media-breakpoint-up(md) {
      min-height: 64px;
      padding-top: 20px;
      padding-left: 147px;
    }
  }

  &__legend {
    font-size: 16px;
    font-weight: $font-weight-bold;
    left: 23px;
    line-height: 1.188; // = 19px
    margin: 0;
    padding: 0;
    position: absolute;
    top: 13px;
    width: auto;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      font-weight: $font-weight-normal;
      left: 39px;
      line-height: 1.188;
      top: 22px;
    }
  }

  &__main {
    background: $white;
    border-radius: 0 0 10px 10px;
    padding: 20px 23px;

    @include media-breakpoint-up(xl) {
      padding: 20px 32px 20px;
    }
  }

  &__group {
    margin-bottom: 0;

    &:not(:last-child) {
      @include media-breakpoint-down(lg) {
        margin-bottom: 32px;
      }
    }

    &:nth-child(-n + 3) {
      @include media-breakpoint-up(xl) {
        max-width: 300px;
      }
    }
  }

  &__wrap {
    .icon {
      margin-right: 16px;

      &-participant {
        width: 17px;
      }

      &-event,
      &-calendar {
        width: 26px;
      }

      &-gamme {
        width: 28px;
      }

      & ~ #{$parent}__field {
        width: calc(100% - 40px);
      }
    }

    .search-form__select {
      min-width: 230px;
    }
  }

  &__field {
    position: relative;
  }

  &__label {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: 1.188;
    margin-bottom: 17px;
  }

  &--aside {
    padding-bottom: 17px;

    @include media-breakpoint-up(md) {
      padding-bottom: 24px;
    }

    #{$parent} {
      &__group {
        padding: 0 20px;

        @include media-breakpoint-up(md) {
          padding-left: 24px;
          padding-right: 24px;
        }

        &:first-child {
          background: $gold-2;
          color: $white;
          padding-bottom: 18px;
          padding-top: 18px;
        }

        &:not(:last-of-type) {
          margin-bottom: 28px;
        }

        &:nth-child(-n + 3) {
          @include media-breakpoint-up(xl) {
            max-width: initial;
          }
        }
      }

      &__field {
        max-width: calc(100% - 40px);
      }
    }

    #msgErreur {
      margin: 15px auto 0;
      text-align: center;
      width: calc(100% - 48px);
    }
  }
}
