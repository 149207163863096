.social-links {
  margin-bottom: 26px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 22px;
    }
  }

  &__link {
    background: $white;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    .icon {
      path {
        fill: $color-main;
      }
    }
  }

  &__icon {
    &--facebook {
      width: 12px;
    }
    &--instagram,
    &--twitter {
      width: 20px;
    }
    &--linkedin,
    &--youtube {
      width: 18px;
    }
  }
}
