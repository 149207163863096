.form-filters {
  min-height: 167px;
  padding-bottom: 18px;
  padding-top: 42px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 18px;
    padding-top: 16px;
  }

  &__fieldset {
    position: relative;

    &:first-child {
      margin-bottom: 30px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 18px;
      }
    }
  }

  &__event {
    .form-group {
      @include media-breakpoint-up(lg) {
        min-width: 258px;
      }

      &:first-child {
        @include media-breakpoint-up(lg) {
          min-width: auto;
          max-width: 166px;
        }
      }
    }

    .form-control {
      border-color: transparent;
    }
  }

  &__legend {
    color: $white;
    font-size: 21px;
    line-height: 1.19; // 25px
    margin-bottom: 8px;
    position: relative;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 1.222; // 22px
      margin-bottom: 8px;
    }

    &::after {
      @include media-breakpoint-down(md) {
        font-size: 18px;
        content: attr(data-open);
        cursor: pointer;
        line-height: 1.222; // 22px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-decoration: underline;
      }
    }

    &:hover {
      &::after {
        @include media-breakpoint-down(md) {
          text-decoration: none;
        }
      }
    }

    &.show {
      &::after {
        @include media-breakpoint-down(md) {
          content: attr(data-close);
        }
      }
    }
  }

  .custom-select,
  .form-control {
    // Placeholder
    &::placeholder {
      color: $color-main;
      opacity: 1;
    }
  }
}
