.form-devis {
  &__intro {
    font-size: 15px;
    font-weight: $font-weight-semibold;
    line-height: 1.2; // 18px
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 28px;
    }
  }

  .col-lg-1 {
    padding-bottom: $grid-gutter-width/2;
    padding-top: $grid-gutter-width/2;

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__mentions {
    background: url(inline-icons("clock", $color-main)) no-repeat left center;
    background-size: 17px;
    padding-left: 31px;
    font-size: 15px;
    line-height: 1.2; // 18px

    @include media-breakpoint-down(lg) {
      margin-bottom: $grid-gutter-width/2;
    }
  }

  .btn-submit {
    width: 100%;
  }
}
