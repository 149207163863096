.form-booking {
  &__fieldset {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__legend {
    font-size: 18px;
    font-weight: $font-weight-bold;
    line-height: 1.222; // =22px
    margin-bottom: 20px;
  }

  &__cgv {
    .custom-control-label {
      font-size: 14px;
      line-height: 1.214; // 17px
    }

    a {
      text-decoration: underline;

      @include plain-hover-focus() {
        color: $color-main;
      }

      @include hover-focus() {
        text-decoration: none;
      }
    }
  }

  &__payment {
    @include media-breakpoint-down(lg) {
      margin-bottom: $grid-gutter-width/2;
    }

    &-text {
      font-size: 14px;
      line-height: 1.214; // 17px
      margin-left: 24px;
    }
  }

  .btn-submit {
    width: 100%;
  }
}
