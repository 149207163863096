.block-options {
  margin-bottom: 24px;

  &__title {
    font-size: 15px;
    font-weight: $font-weight-bold;
    line-height: 1.2; // 18px
    margin-bottom: 11px;
  }

  &__desc {
    font-size: 14px;
    line-height: 1.214; // 17px
    margin-bottom: 27px;
  }
}
