.transports {
  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__visuals {
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0px;
    }
  }

  &__img {
    display: block;
    max-width: 28px;

    &:first-child {
      margin-right: 13px;
    }

    &:nth-child(n + 3) {
      margin-left: 7px;
    }
  }
}
