.animation-detail {
  border: 1px solid $gray-7;
  padding: 15px;
  border-radius: 5px;

  @include media-breakpoint-up(xl) {
    background: $gray-1;
    border-color: $color-main;
    min-height: 94px;
    padding: 10px 30px;
    position: relative;
  }

  &__title {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: 1.2; // 24px

    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }

  &__content {
    font-size: 15px;
    line-height: 1.2; // 18px
    max-width: 568px;
    margin-bottom: 28px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  &__desc {
    margin-bottom: 20px;
  }

  &__add {
    font-weight: $font-weight-normal;

    @include media-breakpoint-down(sm) {
      display: block;
      margin: 0 auto;
    }

    @include media-breakpoint-up(xl) {
      bottom: 18px;
      min-width: 225px;
      position: absolute;
      right: 32px;
    }

    .default {
      &::before {
        content: "+ ";
      }
    }

    .selected {
      display: none;
    }

    &[disabled] {
      background: url(inline-icons("checked", $yellow-1)) no-repeat left top;
      background-size: 17px 16px;
      background-position: 22px center;
      border: 2px solid $yellow-1;
      cursor: not-allowed;
      opacity: 1;
      padding-left: 63px;
      text-align: left;

      .default {
        display: none;
      }

      .selected {
        display: block;
      }

      @include plain-hover-focus() {
        background-color: $gray-1;
        box-shadow: none;
      }
    }
  }

  &__figure {
    margin-bottom: 10px;
    margin-right: 10px;

    @include media-breakpoint-down(lg) {
      max-width: 95px;
      float: left;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 20px;
    }
  }

  &__img {
    @include media-breakpoint-up(xl) {
      border: 1px solid $color-main;
      max-width: 172px;
    }
  }
}
