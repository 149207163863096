.result-block {
  $parent: &;
  $establishment: ".result-list--establishment";

  background: $white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__visual {
    background: rgba($black, 0.05);
    cursor: pointer;
    margin: 0;
    position: relative;

    &::before {
      display: block;
      content: "";
      padding-top: percentage(200/373);
    }

    &::after {
      @include loader;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -16px;
      margin-top: -16px;
      z-index: 1;
    }
  }

  &__figure {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &__img {
    color: transparent;
    transition: transform 4s;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__header {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    &::after,
    &::before {
      content: "";
      display: block;
      pointer-events: none;
    }

    &::before {
      padding-top: percentage(200/373);
    }

    &::after {
      background: linear-gradient(180deg, rgba($black, 0) 21.71%, $black 100%);
      bottom: 0;
      left: 0;
      min-height: 90px;
      position: absolute;
      width: 100%;
      z-index: 0;
    }
  }

  &__head {
    color: $white;
    height: 100%;
    left: 0;
    padding: 0 16px 10px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }

  &__title,
  &__metas,
  &__ranking {
    z-index: 3;
  }

  &__title {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 7px;

    #{$establishment} & {
      margin-bottom: 0;
      max-width: 58%;
    }
  }

  &__ranking {
    border-radius: 50%;
    height: 34px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 34px;

    .icon {
      fill: $white;
      margin: auto;
    }
  }

  &__metas {
    font-size: 12px;
    line-height: 1.333; // 16px

    #{$establishment} & {
      padding-bottom: 3px;
    }
  }

  &__address,
  &__location,
  &__private {
    font-size: 12px;
    line-height: 1.333; // 16px
  }

  &__location {
    max-width: 130px;

    #{$establishment} & {
      max-width: none;
    }

    &::before {
      background: url(inline-icons("place", $white)) no-repeat left top;
      background-size: contain;
      content: "";
      display: block;
      flex-shrink: 0;
      height: 13px;
      margin-right: 8px;
      width: 10px;
    }
  }

  &__private {
    &::before {
      background: url(inline-icons("private", $white)) no-repeat left top;
      background-size: contain;
      content: "";
      display: block;
      height: 13px;
      margin-right: 8px;
      width: 11px;
    }

    &--no {
      &::before {
        background-image: url(inline-icons("non-private", $white));
        height: 13px;
        width: 14px;
      }
    }
  }

  &__content {
    padding: 16px;

    @include media-breakpoint-up(xl) {
      padding-right: 32px;
    }

    #{$establishment} & {
      display: none;
    }
  }

  &__desc {
    font-size: 13px;
    line-height: 1.231; // 16px
    margin-bottom: 16px;

    strong {
      font-weight: $font-weight-semibold;
    }
  }

  &__pricing {
    &-main,
    &-secondary {
      display: inline-block;
    }

    &-main {
      font-size: 18px;
      line-height: 0.889; // 16px
      margin-right: 14px;
    }

    &-secondary {
      font-size: 13px;
      line-height: 1.231; // 16px
    }
  }

  &__contact,
  &__details {
    width: 50%;
  }

  &__contact {
    border-radius: 0px 0px 0px 10px;
  }

  &__details {
    border-radius: 0px 0px 10px 0px;
  }

  &:not(#{$parent}--list) {
    @include media-breakpoint-up(xl) {
      #{$parent} {
        &__figure {
          background-image: none !important;
        }
      }
    }
  }

  // Mode Map
  &--list {
    @include media-breakpoint-up(xl) {
      box-shadow: 0px 6px 16px rgba($black, 0.06);
      display: flex;
      min-height: 186px;

      #{$parent} {
        &__visual {
          flex: 0 0 auto;
          width: 248px;

          &::before {
            padding-top: percentage(186/248);
          }
        }

        &__img {
          opacity: 0;
        }

        &__container {
          cursor: pointer;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: space-between;
        }

        &__header {
          position: static;
          pointer-events: initial;

          &::after,
          &::before {
            display: none;
          }
        }

        &__head {
          color: $color-main;
          height: auto;
          padding-bottom: 5px;
          padding-top: 8px;
          position: static;

          #{$establishment} & {
            align-items: flex-start !important;
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
        }

        &__title {
          pointer-events: none;
        }

        &__metas {
          #{$establishment} & {
            font-size: 16px;
            padding: 4px 0 0;
          }
        }

        &__location {
          cursor: pointer;
          max-width: none;
          text-decoration: underline;

          &::before {
            background-image: url(inline-icons("place", $color-main));
          }

          &:hover {
            text-decoration: none;
          }
        }

        &__private {
          &::before {
            background-image: url(inline-icons("private", $color-main));
          }
        }

        &__content {
          display: block;
          padding: 0 16px;
        }

        &__desc {
          margin-bottom: 8px;

          #{$establishment} & {
            font-size: 14px;
            margin-bottom: 0;
          }
        }

        &__contact {
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  &--map {
    border-radius: 0;
    cursor: pointer;
    display: block;

    #{$parent} {
      &__title {
        font-weight: $font-weight-semibold;
        max-width: 250px;
      }

      &__img {
        display: block;
        width: 250px;

        &:hover {
          transform: none;
        }
      }

      &__details {
        border-bottom-left-radius: 10px;
        width: 250px;
      }
    }
  }
}
