:root {
  --litepickerBgColor: #fff;

  --litepickerMonthHeaderTextColor: #333;
  --litepickerMonthButton: #9e9e9e;
  --litepickerMonthButtonHover: #2196f3;
  --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 7); // 7 days
  --litepickerMonthWeekdayColor: #9e9e9e;

  --litepickerDayColor: #333;
  --litepickerDayColorHover: #2196f3;
  --litepickerDayIsTodayColor: #f44336;
  --litepickerDayIsInRange: #bbdefb;
  --litepickerDayIsLockedColor: #9e9e9e;
  --litepickerDayIsBookedColor: #9e9e9e;
  --litepickerDayIsStartColor: #fff;
  --litepickerDayIsStartBg: #2196f3;
  --litepickerDayIsEndColor: #fff;
  --litepickerDayIsEndBg: #2196f3;
  --litepickerDayWidth: 38px;

  --litepickerButtonCancelColor: #fff;
  --litepickerButtonCancelBg: #9e9e9e;
  --litepickerButtonApplyColor: #fff;
  --litepickerButtonApplyBg: #2196f3;

  --litepickerButtonResetBtn: #909090;
  --litepickerButtonResetBtnHover: #2196f3;

  --litepickerHighlightedDayColor: #333;
  --litepickerHighlightedDayBg: #ffeb3b;
}

.show-week-numbers {
  --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 8);
}

.litepicker {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-family: $font-family-base;
  font-size: 0.8em;
  display: none;

  .container {
    &__main {
      display: flex;
    }
    &__months {
      display: flex;
      flex-wrap: wrap;
      background-color: var(--litepickerBgColor);
      border-radius: 5px;
      box-shadow: 0 0 5px #ddd;
      width: calc(
        var(--litepickerMonthWidth) + 10px
      ); // 10px is padding (left 5px, right: 5px)
      box-sizing: content-box;

      &.columns-2 {
        width: calc((var(--litepickerMonthWidth) * 2) + 20px);
      }

      &.columns-3 {
        width: calc((var(--litepickerMonthWidth) * 3) + 30px);
      }

      &.columns-4 {
        width: calc((var(--litepickerMonthWidth) * 4) + 40px);
      }

      &.split-view {
        .month-item {
          &-header {
            .button-previous-month,
            .button-next-month {
              visibility: visible;
            }
          }
        }
      }

      .month-item {
        padding: 5px;
        width: var(--litepickerMonthWidth);
        box-sizing: content-box;

        &-header {
          display: flex;
          justify-content: space-between;
          // font-weight: 500;
          padding: 10px 5px;
          text-align: center;
          align-items: center;
          color: var(--litepickerMonthHeaderTextColor);
          // additionnal styles
          font-size: 15px;
          line-height: 1.2; // 18px
          // (end) additionnal styles

          div {
            flex: 1;

            > .month-item-name {
              margin-right: 5px;
              font-weight: $font-weight-normal;
              // additionnal styles
              display: inline-block;
              &::first-letter {
                text-transform: uppercase;
              }
              // (end) additionnal styles
            }

            > .month-item-year {
              padding: 0;
            }
          }

          .reset-button {
            color: var(--litepickerButtonResetBtn);

            > svg,
            > img {
              fill: var(--litepickerButtonResetBtn);
              pointer-events: none;
            }

            &:hover {
              color: var(--litepickerButtonResetBtnHover);

              > svg {
                fill: var(--litepickerButtonResetBtnHover);
              }
            }
          }

          .button-previous-month,
          .button-next-month {
            visibility: hidden;
            text-decoration: none;
            color: var(--litepickerMonthButton);
            padding: 3px 5px;
            border-radius: 3px;
            transition: color 0.3s, border 0.3s;
            cursor: default;

            > svg,
            > img {
              fill: var(--litepickerMonthButton);
              pointer-events: none;
            }

            &:hover {
              color: var(--litepickerMonthButtonHover);

              > svg {
                fill: var(--litepickerMonthButtonHover);
              }
            }
          }
        }

        &-weekdays-row {
          display: flex;
          justify-self: center;
          justify-content: flex-start;
          color: var(--litepickerMonthWeekdayColor);

          > div {
            padding: 5px 0;
            // font-size: 85%;
            flex: 1;
            width: var(--litepickerDayWidth);
            text-align: center;
            // additionnal styles
            font-size: 13px;
            font-weight: 300;
            line-height: 1.231; // 16px
            // (end) additionnal styles
          }
        }

        &:first-child {
          .button-previous-month {
            visibility: visible;
          }
        }

        &:last-child {
          .button-next-month {
            visibility: visible;
          }
        }

        &.no-previous-month {
          .button-previous-month {
            visibility: hidden;
          }
        }

        &.no-next-month {
          .button-next-month {
            visibility: hidden;
          }
        }
      }
    }

    &__days {
      display: flex;
      flex-wrap: wrap;
      justify-self: center;
      justify-content: flex-start;
      text-align: center;
      box-sizing: content-box;

      > div,
      > a {
        padding: 5px 0;
        width: var(--litepickerDayWidth);
      }

      .day-item {
        color: var(--litepickerDayColor);
        text-align: center;
        text-decoration: none;
        border-radius: 3px;
        transition: color 0.3s, border 0.3s;
        cursor: default;
        // additionnal styles
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.214; // 17px
        // (end) additionnal styles

        &:hover {
          color: var(--litepickerDayColorHover);
          // box-shadow: inset 0 0 0 1px var(--litepickerDayColorHover);
        }

        &.is-today {
          color: var(--litepickerDayIsTodayColor);
        }

        &.is-locked {
          color: var(--litepickerDayIsLockedColor);

          &:hover {
            color: var(--litepickerDayIsLockedColor);
            box-shadow: none;
            cursor: default;
          }
        }

        &.is-booked {
          color: var(--litepickerDayIsBookedColor);

          &:hover {
            color: var(--litepickerDayIsBookedColor);
            box-shadow: none;
            cursor: default;
          }
        }

        &.is-in-range {
          background-color: var(--litepickerDayIsInRange);
          border-radius: 0;
        }

        &.is-start-date {
          color: var(--litepickerDayIsStartColor);
          background-color: var(--litepickerDayIsStartBg);
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &.is-flipped {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        &.is-end-date {
          color: var(--litepickerDayIsEndColor);
          background-color: var(--litepickerDayIsEndBg);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          &.is-flipped {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &.is-start-date.is-end-date {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.is-highlighted {
          color: var(--litepickerHighlightedDayColor);
          background-color: var(--litepickerHighlightedDayBg);
        }
      }

      .week-number {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9e9e9e;
        font-size: 85%;
      }
    }

    &__footer {
      text-align: right;
      padding: 10px 5px;
      margin: 0 5px;
      background-color: #fafafa;
      box-shadow: inset 0px 3px 3px 0px #ddd;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .preview-date-range {
        margin-right: 10px;
        font-size: 90%;
      }

      .button-cancel {
        background-color: var(--litepickerButtonCancelBg);
        color: var(--litepickerButtonCancelColor);
        border: 0;
        padding: 3px 7px 4px;
        border-radius: 3px;

        > svg,
        > img {
          pointer-events: none;
        }
      }

      .button-apply {
        background-color: var(--litepickerButtonApplyBg);
        color: var(--litepickerButtonApplyColor);
        border: 0;
        padding: 3px 7px 4px;
        border-radius: 3px;
        margin-left: 10px;
        margin-right: 10px;

        &:disabled {
          opacity: 0.7;
        }

        > svg,
        > img {
          pointer-events: none;
        }
      }
    }

    &__tooltip {
      position: absolute;
      margin-top: -4px;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      white-space: nowrap;
      font-size: 11px;
      pointer-events: none;
      visibility: hidden;

      &:before {
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
        border-top: 5px solid rgba(0, 0, 0, 0.12);
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        content: "";
      }

      &:after {
        position: absolute;
        bottom: -4px;
        left: calc(50% - 4px);
        border-top: 4px solid #fff;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
      }
    }
  }

  &-open {
    overflow: hidden;
  }

  &-backdrop {
    display: none;
    background-color: #000;
    opacity: 0.3;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
