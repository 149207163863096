.view360 {
  display: none;

  .gallery--360show & {
    display: block;
  }

  iframe {
    width: 100%;
  }
}

.thumb360 {
  border-radius: 5px;
  display: block;
  position: absolute;
  right: 0;
  bottom: 21px;
  height: 80px;
  width: 101px;
  overflow: hidden;

  &__img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: 1;
    max-width: 150%;
  }

  &__button {
    background: rgba($black, 0.2);
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .icon {
    width: 50px;
  }
}
