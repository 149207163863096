.animations-nav {
  margin-bottom: 12px;

  &__link {
    background: $gray-1;
    border: 1px solid $gray-5;
    border-radius: 5px;
    color: $color-main;
    font-size: 16px;
    height: 56px;
    line-height: 1.188; // 19px
    min-height: auto;
    padding: 9px 0;
    position: relative;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: 111px;
    }

    @include hover-focus() {
      background: rgba($color-main, 0.8);
      border-color: $color-main;
      color: $white;
      text-decoration: none;
    }

    &:active,
    &.active {
      font-weight: $font-weight-bold;
      text-decoration: none;
      background: $color-main;
      border-color: $color-main;
      color: $white;

      &::after {
        @include media-breakpoint-up(xl) {
          bottom: -13px;
          content: "";
          position: absolute;
          left: 50%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 18px 12px 0 12px;
          border-color: $color-main transparent transparent transparent;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  &__collapse {
    &:not(.collapsed) {
      font-weight: $font-weight-bold;
      text-decoration: none;
      background: $color-main;
      border-color: $color-main;
      color: $white;
    }
  }
}
