.ui-slider {
  &.ui-slider-horizontal {
    background: $color-main;
    border: none;
    border-radius: 0;
    height: 1px;
    margin: 0 4px; // to check ?

    .ui-slider-range,
    .ui-slider-handle {
      background: $yellow-1;
    }

    .ui-slider-range {
      height: 2px;
    }

    .ui-slider-handle {
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 17px;
      top: 50% !important;
      transform: translateY(-50%) !important;
      width: 17px;
    }
  }
}
