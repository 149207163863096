.animations-content {
  min-height: 256px;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    background: $gray-1;
    border: 1px solid $color-main;
    border-radius: 5px;
    margin-bottom: 18px;
  }
}
