.toc {
  background: $color-main;
  left: 0;
  position: absolute;
  top: 36px;
  width: 100%;
  z-index: 90;

  @include media-breakpoint-up(xl) {
    top: 0;
  }

  &--sticky {
    position: fixed;
    top: 0;
  }

  &__list {
    overflow-x: auto;
    padding-bottom: 11px;
    padding-top: 10px;
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    font-size: 16px;
    line-height: 1.188; // 19px

    &:not(:last-child) {
      margin-right: 30px;

      @include media-breakpoint-up(md) {
        margin-right: 78px;
      }
    }
  }

  &__link {
    @include plain-hover-focus() {
      color: $white;
    }
  }
}
