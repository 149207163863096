.article {
  $article: &;

  &__content {
    padding-bottom: 70px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 80px;
    }

    > p {
      font-size: 16px;
      line-height: 1.5 !important;

      &:not(:last-child) {
        margin-bottom: $grid-gutter-width;
      }

      a {
        @include article-link;
      }
    }
  }

  h1:not(.result-block__title),
  h2:not(.result-block__title) {
    @include area-title;
    margin-top: 49px;

    @include media-breakpoint-up(lg) {
      margin-top: 56px;
    }
  }

  strong {
    font-weight: $font-weight-semibold;
  }

  video#presentation {
    border: 1px solid rgba($black, 0.5);
  }

  &--no-padding {
    #{$article}__content {
      padding-bottom: 0;
    }
  }

  // Team
  &-team {
    &__intro {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }
  }

  &-join {
    &__visual {
      text-align: center;
    }

    img {
      border-radius: 50%;
    }

    a {
      @include article-link;
    }
  }
}
