.results-map {
  background: #e8e8e8;
  display: none;
  position: relative;
  height: calc(100vh - 166px);
  margin: 0 auto 32px;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 110px);
  }

  @include media-breakpoint-up(xl) {
    height: calc(100vh - 372px); // 409px (Header + Filters + Sort)
    margin: 0;
    position: absolute;
    right: 0;
    top: -40px;
    width: percentage(688/1440);
  }

  .page.nos_etablissements & {
    @include media-breakpoint-up(xl) {
      // height: calc(100vh - 409px); // 409px (Header + Filters + Sort)
    }
  }

  .viewMode-map & {
    display: block;
  }

  &--full {
    .viewMode-map & {
      @include media-breakpoint-up(xl) {
        height: 100vh;
      }
    }
  }

  &--sticky {
    .viewMode-map & {
      @include media-breakpoint-up(xl) {
        position: fixed;
        top: 0 !important;
      }
    }
  }

  &::before {
    @include loader;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}

.viewMode-map {
  .result-tools {
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }
  }
  .results {
    position: relative;
  }

  & + .footer-site {
    display: none;
  }
}

#map-canvas {
  box-sizing: initial;
  height: 100%;
  width: 100%;
  img {
    max-width: none;
  }
}
