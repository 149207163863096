.dropdown-toggle {
  &.selected {
    background: $yellow-1;
    border-color: $yellow-1 !important;
    padding-right: 0.75rem;
    position: relative;

    &::after {
      content: attr(data-selected);
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      display: flex;
      height: 20px;
      background: $color-main;
      color: $white;
      width: 20px;
      font-weight: $font-weight-bold;
      font-size: 12px;
      line-height: 1.167; // =14px
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
    }
  }
}
