.access {
  &__main {
    margin-bottom: 58px;

    .icon {
      display: block;
      margin: 0 0 9px;

      @include media-breakpoint-up(md) {
        margin: 0 auto 18px;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  &__address {
    .icon {
      width: 32px;
    }
  }

  &__car {
    .icon {
      width: 36px;
    }
  }

  &__parking {
    .icon {
      width: 33px;
    }
  }

  &__text {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}
