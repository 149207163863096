.back-link {
  background: $white url(inline-icons("arrow-left-brown", $color-main))
    no-repeat 20px center;
  background-size: 10px 12px;
  border-top: 1px solid $color-main;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.188; // 19px
  padding: 9px $grid-gutter-width/2 7px 40px;
  text-align: left;
  text-decoration: none;

  @include media-breakpoint-down(lg) {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 90;
  }

  @include media-breakpoint-up(xl) {
    background-position: left center;
    border: none;
    padding-left: 20px;
    width: 50%;
  }

  @include hover-focus-active {
    text-decoration: underline;
  }
}
