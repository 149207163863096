@mixin field-icon(
  $icon,
  $width,
  $height,
  $left,
  $color: $white,
  $override: false
) {
  &::before {
    background: url(inline-icons($icon, $color)) no-repeat left top;
    background-size: $width $height;
    @if $override != true {
      content: "";
      display: block;
      height: $height;
      left: $left;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: $width;
      z-index: 10;
    }
  }
}

@mixin form-control-appearance($select: false, $readonly: false) {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @if $select == true {
    background: $custom-select-bg $custom-select-background;
  }
  @if $readonly == true {
    background-color: $input-disabled-bg;
  }

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
  @include form-control-focus($ignore-warning: true);
}

@mixin area-title {
  font-size: 28px;
  line-height: 1.429; // 40px
  margin-bottom: 32px;

  @include media-breakpoint-up(md) {
    line-height: 1.214;
    text-align: center;
  }
}

@mixin article-link {
  color: $gold-4;
  font-weight: $font-weight-bold;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@mixin disabled-item {
  background: $gray-1;
  border-color: $gray-1;
  color: $gray-5;
  cursor: not-allowed;
}

@mixin plain-hover-focus-active {
  &,
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin loader($size: 24px, $background: $gray-4) {
  animation: spin 1s linear infinite;
  content: "";
  border: 2px solid $background;
  border-top-color: $background;
  border-top-style: solid;
  border-top-width: 2px;
  border-top: 2px solid $color-main;
  border-radius: 50%;
  width: $size;
  height: $size;
}
