.section-filters {
  $parent: &;
  $form: form-filters;

  &__title {
    color: $white;
    font-size: 26px;
    line-height: 1.308; // 34px
    margin-bottom: 0;
    padding: 40px 0 0;
    text-align: center;
  }

  &--establishment {
    .form-control,
    .custom-select {
      border-color: $white;
    }

    .#{$form} {
      padding-top: 32px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 26px;
      }

      &__fieldset {
        position: relative;

        &:nth-of-type(1) {
          @include media-breakpoint-up(lg) {
            padding-left: 208px;
            margin-bottom: 30px;
          }
        }

        &:nth-of-type(2) {
          @include media-breakpoint-up(lg) {
            padding-left: 161px;
          }
        }
      }

      &__legend {
        @include media-breakpoint-up(lg) {
          left: 0;
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: auto;
        }
      }
    }
  }
}
