.main-menu {
  $parent: &;

  &__item {
    &:not(:last-child) {
      margin-bottom: 26px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }

    &:not(:first-child) {
      @include media-breakpoint-up(xl) {
        margin-left: 34px;
      }
    }

    @include media-breakpoint-up(xl) {
      display: inline-block;
    }

    // With Dropdown
    &.dropdown {
      #{$parent}__link {
        display: block;
        padding-right: 26px;
        position: relative;

        &::after {
          background: url(inline-icons("arrow-bottom")) no-repeat center center;
          background-size: contain;
          content: "";
          display: block;
          height: 6px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
        }
      }

      &.show {
        #{$parent}__link {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    .dropdown-menu {
      @include media-breakpoint-down(lg) {
        position: static !important;
        float: none;
      }
    }
  }

  &__link {
    font-size: 18px;
    line-height: 1.167; // 21px
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(xl) {
      font-size: 16px;
      line-height: 1.188; // 19px
    }

    &,
    &:active,
    &:focus,
    &:hover {
      color: $color-main;
    }
  }

  .dropdown-menu {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-item {
    background-image: url(inline-icons("arrow-right-brown", $color-main));
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 10px 10px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.menu-open {
  overflow-y: hidden;
}
