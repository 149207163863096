.site-branding {
  font-size: 10px;
  margin: 0;

  &__link {
    display: block;

    @include media-breakpoint-up(xl) {
      text-align: center;
      max-width: 132px;
    }

    &,
    &:active,
    &:hover,
    &:focus {
      color: $color-main;
      text-decoration: none;
    }
  }

  &__logo,
  &__baseline {
    display: inline-block;
    vertical-align: middle;
  }

  &__logo {
    margin-right: 12px;
    max-width: 40px;

    @include media-breakpoint-up(xl) {
      display: block;
      margin: 0 auto 1px;
      max-width: 64px;
    }
  }

  &__baseline {
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
}
