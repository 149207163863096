.input-group {
  > .form-control,
  > .custom-select {
    &:not(:last-child) {
      border-right: none;
    }
    &:not(:first-child) {
      border-left: none;
      padding-left: 0;
    }
  }
}

.input-group-text {
  padding-bottom: 0;
  padding-top: 0;
}

.form-control,
.custom-select {
  appearance: none;

  &.read-only {
    background-color: $input-disabled-bg;
  }

  // Disabled
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

.custom-select {
  cursor: pointer;
  text-align: left;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* DROPDOWN */
.dropdown-toggle {
  &::after {
    display: none;
  }
}

.dropdown {
  &-menu {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &-title {
    font-size: 15px;
    line-height: 1.2; // =18px
    margin-bottom: 1.0625rem; // =17px
  }

  // Range
  &-range {
    .dropdown-menu {
      border-color: rgba($color-main, 0.15);
      box-shadow: 0px 6px 16px rgba($black, 0.06);
      min-width: 100%;

      @include media-breakpoint-up(lg) {
        min-width: 310px;
      }

      .search-form & {
        @include media-breakpoint-up(lg) {
          min-width: 270px;
        }
      }
    }
  }

  // Critères
  &-morecriteria {
    & > .dropdown-menu {
      min-width: auto;
    }

    &.show-dropdown {
      & > .dropdown-menu {
        display: block;
      }
    }

    div[class*="form-filters"] {
      &:not(:last-child) {
        @include media-breakpoint-down(sm) {
          margin-bottom: 15px;
        }
      }

      &:nth-child(-n + 3) {
        @include media-breakpoint-between(sm, lg) {
          margin-bottom: 15px;
        }
      }
    }
  }

  &-shadow {
    overflow: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 50%;

    .filter-price & {
      padding: 0 !important;
      width: 20%;
    }
  }
}

/* MODAL */
.modal {
  $modal: &;

  &-header {
    padding-bottom: 0;
    position: relative;

    #{$modal}-close {
      margin: 0;
      padding: 0;
    }
  }

  @at-root {
    #{&}-close {
      background: $color-main;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      transform: translate(20%, -50%);
      justify-content: center;
      height: 36px;
      width: 36px;

      @include media-breakpoint-up(md) {
        transform: translate(50%, -50%);
      }

      .icon {
        height: 16px;
        width: 16px;
      }
    }
  }

  @at-root {
    #{&}-close-large {
      background: none;
      border: none;
      border-bottom: 1px solid $color-main;
      font-size: 12px;
      line-height: 1.167; // =14px
      padding: 12px 0 17px 47px;
      position: relative;
      text-align: left;
      width: 100%;

      &::before {
        background: url(inline-icons("arrow-right", $color-main)) no-repeat left
          top;
        background-size: 16px 16px;
        content: "";
        height: 16px;
        position: absolute;
        left: 16px;
        top: 46%;
        transform: translateY(-50%) rotate(180deg);
        width: 16px;
      }
    }
  }

  &-title {
    font-size: 22px;
    font-weight: $font-weight-semibold;
    line-height: 1.227; // 27px
  }

  &-footer {
    padding-top: 0;

    & > * {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

/* NAV */
.nav-tabs {
  border-bottom: none;
  justify-content: space-between;

  .nav-link {
    background: $gray-1;
    border-color: $gray-5;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.188; // =19px
    margin-bottom: 0;
    color: $color-main;
    padding: 9px 11px;
    max-width: 120px;
    min-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @include hover-focus() {
      background: $color-main;
      border-color: $color-main;
      color: $white;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background: $color-main;
    border-color: $color-main;
    color: $white;
    font-weight: $font-weight-bold;
  }
}

// BOUTONS
.btn {
  text-transform: uppercase;

  // CTA
  &-cta {
    @include plain-hover-focus-active() {
      color: $color-main;
    }
  }

  // Contact
  &-outline-contact {
    color: $color-main;

    @include hover() {
      color: $color-main;
      // text-decoration: $link-hover-decoration;
    }
  }

  // Options
  &-options {
    &::before {
      content: "+ ";
    }
  }

  // Disabled
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

// TABS
.tab-content {
  & > .tab-pane {
    display: none;
  }

  & > .active {
    display: block;
  }
}
