.user-review {
  background: $gray-1;
  border-radius: 10px;
  max-width: 270px;
  min-height: 298px;
  padding: 56px $grid-gutter-width 28px;
  position: relative;
  text-align: center;
  white-space: initial;
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding-left: 35px;
    padding-right: 35px;
    margin: 0;
    max-width: 340px;
    min-height: 245px;
  }

  &__title {
    font-size: 18px;
    line-height: 1.222;
    margin-bottom: 0;
    order: 1;
  }

  &__job {
    font-size: 14px;
    font-weight: $font-weight-normal;
    line-height: 1.214;
  }

  &__visual {
    border-radius: 50%;
    height: 70px;
    left: 50%;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 70px;
  }

  &__content {
    font-size: 16px;
    margin-bottom: 24px;
  }

  &::after,
  &::before {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 26px 17px;
    content: "";
    display: block;
    height: 17px;
    position: absolute;
    width: 26px;
  }
  &::before {
    background-image: url(inline-icons("opening-quotes", $yellow-1));
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &::after {
    background-image: url(inline-icons("closing-quotes", $yellow-1));
    transform: translate(50%, 50%);
    right: 0;
    bottom: 0;
  }
}
