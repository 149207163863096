.footer-nav {
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }

  @include media-breakpoint-up(xl) {
    min-width: 202px;
  }

  &:not(:last-of-type) {
    margin-bottom: 33px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 1.214;

    &:not(:last-child) {
      margin-bottom: 33px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 26px;
      }
    }
  }

  &__link {
    @include plain-hover-focus() {
      color: $white;
    }
  }
}
