.product {
  padding: 30px $grid-gutter-width/2 0;
  margin-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }

  @include media-breakpoint-between(lg, xl) {
    max-width: calc(100% - 450px);
  }

  &__header {
    font-size: 16px;
    line-height: 1.188; // 19px
    margin-bottom: 26px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 1.206; // 41px
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &__address {
    @include media-breakpoint-down(sm) {
      margin-top: 8px;
      order: 1;
    }

    @include media-breakpoint-up(lg) {
      padding: 6px 0 7px;
    }
  }

  &__state {
    background: $color-main url(inline-icons("private", $white)) no-repeat 16px
      center;
    background-size: 12px 14px;
    border-radius: 30px;
    color: $white;
    padding: 6px 16px 7px 38px;

    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }

    &--no {
      background-image: url(inline-icons("non-private", $white));
      background-size: 13px 14px;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__gallery {
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: $font-weight-semibold;
    line-height: 1.2; // 24px
    margin-bottom: 20px;

    &--space {
      &-xs {
        margin-bottom: 8px;
      }

      &-xl {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    font-size: 14px;
    line-height: 1.214; // 17px

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
