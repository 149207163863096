.area {
  &__title {
    @include area-title;
  }

  &__content {
    p {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }

  &__media {
    border: 1px solid rgba($black, 0.5);
  }
}

// Area BENEFITS
.area-benefits {
  $benefits: &;

  background: $gold-2;
  color: $white;
  padding: 50px 0;

  @include media-breakpoint-up(lg) {
    padding-bottom: 87px;
    padding-top: 63px;
  }

  &__title {
    line-height: 1.214;
    margin-bottom: 40px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  .page-contact & {
    background: none;
    color: $color-main;
  }

  &--big {
    // #{$benefits} {
    //   &__title {
    //     margin-bottom: 0;
    //   }
    // }

    a {
      &,
      &:active,
      &:focus,
      &:hover {
        color: $white !important;
      }
    }
  }
}

// Area TRUST
.area-trust {
  &__title {
    margin-bottom: 0;
    text-align: center;
  }
}

// Area RANKING
.area-ranking {
  background: $gray-2;
  padding: 80px 0;
}
