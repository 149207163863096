.seo-links {
  &:not(:last-child) {
    margin-bottom: 24px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__item {
    font-size: 13px;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__link {
    &,
    &:active,
    &:focus,
    &:hover {
      color: $white;
    }
  }
}
