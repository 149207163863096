.expand {
  border-radius: 10px;
  background: $gray-3;

  &__title {
    background: $gold-2;
    color: $white;
    font-size: 20px;
    font-weight: $font-weight-semibold;
    line-height: 1.2; // 24px
    border-radius: 10px 10px 0 0;
    margin-bottom: 0;
    padding: 14px 16px;

    &--secondary {
      background: $color-main;
    }
  }

  &__content {
    padding: 24px 16px;

    @include media-breakpoint-up(md) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
