.animations-selected {
  $parent: &;

  &__item {
    align-items: center;
    display: flex;
    background: $color-main;
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 1.214;
    margin-top: 8px;
    min-height: 27px;
    padding: 6px 42px 6px 16px;
    position: relative;

    @include media-breakpoint-up(xl) {
      min-height: 50px;
      min-width: 178px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__delete {
    background: url(inline-icons("trash", $white)) no-repeat left top;
    background-position: left center;
    background-size: contain;
    border: none;
    min-height: 21px;
    position: absolute;
    padding: 0;
    right: 14px;
    transform: translateY(-50%);
    top: 50%;
    width: 19px;
  }
}
