html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  max-width: 100%;
}

figure {
  margin-bottom: 0;
}

select[multiple] {
  max-height: 38px;
}

/* Input range HTML */
.range-label {
  margin: 0 auto;
}

input[type="range"].custom-range {
  appearance: none;
  background: none;
  height: auto;
  outline: none;
  opacity: 0.7;
  padding: 0;
  transition: opacity 0.2s;
  width: 100%;
}

// Webkit
input[type="range"].custom-range::-webkit-slider-runnable-track {
  background: $color-main;
  height: 1px;
}

input[type="range"].custom-range::-webkit-slider-thumb {
  appearance: none;
  background: $gold-2;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

// Mozilla
input[type="range"].custom-range::-moz-range-track {
  background: $color-main;
  height: 1px;
}

input[type="range"].custom-range::-moz-range-thumb {
  appearance: none;
  background: $gold-2;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

label {
  &.required {
    &::after {
      content: "*";
    }
  }
}
