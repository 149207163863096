.footer-tools {
  padding: 61px 0;

  @include media-breakpoint-between(md, lg) {
    margin: 0 auto;
    max-width: 296px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 112px;
    padding-top: 0;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 1.188;
    margin-bottom: 12px;

    &:first-of-type {
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
  }

  &__cta {
    display: block;
    background: $white;
    font-size: 14px;
    line-height: 1.214; // 17px
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    width: 100%;
    padding: 17px 14px;

    @include media-breakpoint-up(lg) {
      max-width: 296px;
      bottom: 103px;
      left: 275px;
      position: absolute;
    }
    @include media-breakpoint-up(xl) {
      bottom: auto;
      top: 189px;
      left: 261px;
      line-height: normal;
      max-width: 202px;
    }

    @include plain-hover-focus() {
      color: $color-main;
    }
  }
}
