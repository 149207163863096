.result-list {
  $parent: &;

  padding-bottom: 16px;

  &__item {
    margin-bottom: 32px;
  }

  &--map {
    @include media-breakpoint-up(xl) {
      max-width: 620px;
      flex-direction: column;
    }

    #{$parent} {
      &__item {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  // In Article SEO
  .article-seo & {
    padding-bottom: 0;

    &__item {
      &:last-child {
        margin-bottom: 0;
      }

      &:nth-last-child(-n + 2) {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      &:nth-last-child(-n + 3) {
        @include media-breakpoint-up(ld) {
          margin-bottom: 0;
        }
      }
    }
  }
}
