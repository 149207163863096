.result-tools {
  margin-bottom: 40px;

  &__filter {
    background: $gold-2;
  }

  &__sort {
    background: $white;
    box-shadow: 0px 15px 15px rgba($black, 0.06);
  }
}
